<template>
  <div class="container">
    <PrimePreloader v-if="loadingPage"/>

    <div v-else>
      <div class="education-disciplines-list mt-4">
        <h4 class="text-center">Список всех дисциплин</h4>


        <!-- Update Program Modal -->
        <div class="modal fade" id="updateDisciplineDiscipline" tabindex="-1"
             aria-labelledby="updateDisciplineDisciplineLabel"
             aria-hidden="true">
          <div class="modal-dialog modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="updateDisciplineDisciplineLabel">Обновление</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body" v-if="DISCIPLINE_INFO">

                <div class="col-md-12 mt-4">


                  <div class="form-group row mt-4">
                    <label class="col-md-3 col-form-label">Выберите кафедру</label>
                    <div class="col-md-9">
                      <select class="form-control form-select">
                        <option v-for="(item, index) in educationdiscipline_form.departmentField"
                                :value="item.id"
                                :selected="DISCIPLINE_INFO.department_id==item.id"
                                :key="index">{{ item.name_ru }}
                        </option>
                      </select>
                    </div>
                  </div>


                  <div class="form-group row mt-4">
                    <label for="up_education_direction" class="col-md-3 col-form-label">
                      Выберите Уровень образования
                    </label>
                    <div class="col-md-9">

                      <select class="form-control form-select" id="up_education_direction">
                        <option v-for="(SLItem, index) in educationdiscipline_form.studyLevel"
                                :value="SLItem.id"
                                :selected="DISCIPLINE_INFO.study_level_id==SLItem.id"
                                :key="index">{{ SLItem.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group row mt-4">
                    <label for="up_education_lang" class="col-md-3 col-form-label">
                      Выберите язык дисциплины
                    </label>
                    <div class="col-md-9">

                      <select class="form-control form-select" id="up_education_lang"
                              @input="changeEducationDisciplineData(UPDATE_ITEM,'education_language', $event)">
                        <option v-for="(item, index) in educationdiscipline_form.languages"
                                :value="item.id"
                                :selected="DISCIPLINE_INFO.education_language==item.id"
                                :key="index">{{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group row mt-4">
                    <label for="up_education_speciality_credit"
                           class="col-md-3 col-form-label">Кредит</label>
                    <div class="col-md-9">
                      <input id="up_education_speciality_credit" type="number" class="form-control"
                             placeholder="Credit"
                             :value="DISCIPLINE_INFO.credit">
                    </div>
                  </div>
                  <div class="form-group row mt-4">
                    <label for="up_education_speciality_name" class="col-md-3 col-form-label">
                      Название дисциплины
                    </label>
                    <div class="col-md-9">
                      <input id="up_education_speciality_name" type="text" class="form-control"
                             placeholder="Название дисциплины"
                             :value="DISCIPLINE_INFO.name">
                    </div>
                  </div>
                  <div class="form-group row mt-4">
                    <label for="up_education_speciality_code" class="col-md-3 col-form-label">
                      Код дисциплины
                    </label>
                    <div class="col-md-9">
                      <input id="up_education_speciality_code" type="text" class="form-control"
                             placeholder="Код дисциплины"
                             :value="DISCIPLINE_INFO.code">
                    </div>
                  </div>
                  <div class="form-group row mt-4">
                    <label for="up_description" class="col-md-3 col-form-label">
                      Описание дисциплины
                    </label>
                    <div class="col-md-9">
                    <textarea id="up_description" type="text" class="form-control"
                              placeholder="Описание дисциплины"
                              :value="DISCIPLINE_INFO.description">
                    </textarea>
                    </div>
                  </div>


                  <div class="form-group row mt-4">
                    <label for="up_knowledge" class="col-md-3 col-form-label">Знания</label>
                    <div class="col-md-9">
                    <textarea id="up_knowledge" type="text" class="form-control" placeholder="Знания"
                              :value="DISCIPLINE_INFO.knowledge">
                    </textarea>
                    </div>
                  </div>

                  <div class="form-group row mt-4">
                    <label for="up_skills" class="col-md-3 col-form-label">Навыки</label>
                    <div class="col-md-9">
                    <textarea id="up_skills" type="text" class="form-control" placeholder="Навыки"
                              :value="DISCIPLINE_INFO.skills">
                      </textarea>
                    </div>
                  </div>

                  <div class="form-group row mt-4">
                    <label for="up_abilities" class="col-md-3 col-form-label">Умения</label>
                    <div class="col-md-9">
                    <textarea id="up_abilities" type="text" class="form-control" placeholder="Умения"
                              :value="DISCIPLINE_INFO.abilities">
                    </textarea>
                    </div>
                  </div>

                  <div class="form-group row mt-4">
                    <label for="up_recommendation" class="col-md-3 col-form-label">Рекомендации </label>
                    <div class="col-md-9">
                    <textarea id="up_recommendation" type="text" class="form-control"
                              placeholder="Рекомендации "
                              :value="DISCIPLINE_INFO.recommendation">
                    </textarea>
                    </div>
                  </div>

                  <div class="form-group row mt-4">
                    <label for="up_is_standard" class="col-md-3 col-form-label">
                      Является ли норма времени стандартной?
                    </label>
                    <div class="col-md-9">
                      <input id="up_is_standard" type="checkbox" class="big-checkbox"
                             :checked="DISCIPLINE_INFO.is_standard == 1">
                    </div>
                  </div>


                </div>
                <div class="modal-footer">

                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    Закрыть
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Update Program Modal -->


        <!-- Table Start -->
        <DataTable class="mt-4" :value="educationdiscipline_form.educationDisciplinesList" :paginator="true" :rows="10"
                   showGridlines
                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                   :rowsPerPageOptions="[10,20,50]"
                   filterDisplay="menu"
                   :globalFilterFields="['name', 'code']"
                   v-model:filters="filters"
                   currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" stripedRows
                   responsiveLayout="scroll">

          <template #header>
            <div class="p-d-flex p-jc-between p-ai-center">
              <span class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="filters['global'].value" placeholder="Поиск"/>
              </span>
            </div>
          </template>

          <Column field="code" header="Код"></Column>
          <Column field="name" header="Название"></Column>
          <Column field="department.name_ru" header="Кафедра">
            <template #filter="{filterModel}">
              <Dropdown v-model="filterModel.value" :options="vacancy_form.departments" placeholder="Любой"
                        class="p-column-filter" :showClear="true">
                <template #value="slotProps">
                  <span v-if="slotProps.value">{{slotProps.value}}</span>
                  <span v-else>{{slotProps.placeholder}}</span>
                </template>
                <template #option="slotProps">
                  <span>{{slotProps.option}}</span>
                </template>
              </Dropdown>
            </template>
          </Column>

          <Column field="studyLevel.name" header="Уровень обучения">

            <template #filter="{filterModel}">
              <Dropdown v-model="filterModel.value" :options="studyLevels" placeholder="Любой"
                        class="p-column-filter" :showClear="true">
                <template #value="slotProps">
                  <span v-if="slotProps.value">{{slotProps.value}}</span>
                  <span v-else>{{slotProps.placeholder}}</span>
                </template>
                <template #option="slotProps">
                  <span>{{slotProps.option}}</span>
                </template>
              </Dropdown>
            </template>
          </Column>
          <Column field="language.native_name" header="Язык">
            <template #body="{data}">
              {{ data.language ? data.language.native_name : '' }}
            </template>
            <template #filter="{filterModel}">
              <Dropdown v-model="filterModel.value" :options="studyLanguages" placeholder="Любой"
                        class="p-column-filter" :showClear="true">
                <template #value="slotProps">
                  <span v-if="slotProps.value">{{slotProps.value}}</span>
                  <span v-else>{{slotProps.placeholder}}</span>
                </template>
                <template #option="slotProps">
                  <span>{{slotProps.option}}</span>
                </template>
              </Dropdown>
            </template>
          </Column>
          <Column field="credit" header="Кредит"></Column>

          <Column header="Редактировать">
            <template #body="{data}">
              <button type="button" class="btn btn-warning" data-bs-toggle="modal"
                      data-bs-target="#updateDisciplineDiscipline"
                      @click="updateDiscipline(data.id)">
                <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
              </button>
            </template>
          </Column>
          <Column header="Удалить">
            <template #body="{data}">
              <button type="button" class="btn btn-danger" data-bs-toggle="modal"
                      data-bs-target="#deleteEducationDisciplineModal"
                      @click="openDeleteEducationDisciplineModal(data.id)">
                <i class="fa fa-trash-o" aria-hidden="true"></i>
              </button>
            </template>
          </Column>




        </DataTable>
        <!-- Table End -->

      </div>
    </div>


  </div>
</template>

<script>
import {FilterMatchMode, FilterOperator} from 'primevue/api';
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";


export default {
  name: "EducationDisciplinesList",

  data() {
    return {

      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'name': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
        'code': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
        'language.native_name': {value: null, matchMode: FilterMatchMode.EQUALS},
        'studyLevel.name': {value: null, matchMode: FilterMatchMode.EQUALS},
        'departments': {value: null, matchMode: FilterMatchMode.EQUALS},

      },
      studyLanguages: ['русский язык', 'қазақ тілі', 'English'],
      studyLevels: ['Бакалавриат', 'Магистратура (1 годичное,профильное)', 'Магистратура (1,5 годичное, профильное)',
        'Магистратура (2 годичное, научно-педагогическое)', 'Докторантура (научно-педагогическое направление)', 'MBA', 'EMBA'],

      loadingPage: true,

    }
  },
  computed: {
    ...mapState('educationdiscipline', ['educationdiscipline_form']),
    ...mapState('vacancy', ['departments']),
    ...mapGetters('educationdiscipline', ['DISCIPLINE_INFO']),
  },

  methods: {
    ...mapActions('educationdiscipline', ['GET_EDUCATION_DISCIPLINES_LIST']),
    ...mapActions('vacancy', ['GET_DEPARTMENTS']),
    ...mapMutations('educationdiscipline', ['SET_UPDATE_ID']),
    updateDiscipline(id) {
      console.log(id, 'updateDiscipline')
      this.SET_UPDATE_ID(id)
    },
  },
  async mounted() {
    await this.GET_EDUCATION_DISCIPLINES_LIST()
    this.loadingPage = false;
    await this.GET_DEPARTMENTS()
  }
}
</script>

<style scoped>

</style>